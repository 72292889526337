.my-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
}

* {
  font-family: Robo;
}

.bg-dark {
  background: transparent radial-gradient(closest-side at 50% 50%, #253F8C 0%, #12202D 100%) 0% 0% no-repeat padding-box;

  /* background: transparent radial-gradient(closest-side at 50% 50%, #253f8c 0%, #12202d 100%) 0% 0% no-repeat padding-box; */
  /* height: 1200px; */
  width: 100%;
}

.header {
  margin-left: 20px;
  display: flex;
}

.dropdown {
  width: 25%;
  margin-top: 40px;
  justify-content: end;
  position: absolute;
  right: 1%;
}

.box {
  background: transparent url("../public/boxBg.png") 0% 0% no-repeat;
  justify-content: center;
  margin: auto;
  width: 50%;
  height: 250px;
  position: relative;
}

.heading {
  color: white;
  text-align: center;
}

.profileimg {
  margin-top: 48px;
  margin-left: 50px;
  border-radius: 50px;
  margin-bottom: 110px;
  width: 100px;
  height: 100px;
}

.profileBorder {
  background: transparent url("../public/imageborder.png") 0% 0% no-repeat padding-box;
  position: relative;
  top: 28px;
  left: 9px;
}

.studentName {
  position: absolute;
  top: 30%;
  left: 25%;
  color: #003482;
  font-size: 22px;
}

.studentName2 {
  position: absolute;
  top: 30%;
  left: 25%;
  color: #003482;
  font-size: 22px;
}

.studentName3 {
  position: absolute;
  top: 30%;
  left: 25%;
  color: #003482;
  font-size: 22px;
}

.course {
  color: #07683b;
  font-size: 12px;
}

.schoolname {
  color: #F08C21;
  font-size: 12px;
}

.rankBg {
  background: url("../public/desktop_rank1.svg") 0% 0% no-repeat padding-box padding-box transparent;
  position: absolute;
  width: 143px;
  height: 240px;
  margin: 0px;
  left: 88%;
  top: 6%;
}

.rankBg2 {
  background: url("../public/desktop_rank2.svg") 0% 0% no-repeat padding-box padding-box transparent;
  position: absolute;
  width: 143px;
  height: 240px;
  margin: 0px;
  left: 88%;
  top: 21%;
}

.rankBg3 {
  background: url("../public/desktop_rank3.svg") 0% 0% no-repeat padding-box padding-box transparent;
  position: absolute;
  width: 143px;
  height: 240px;
  margin: 0px;
  left: 88%;
  top: 19%;
}

.ranktext {
  color: white;
  position: absolute;
  top: 25%;
  right: 53%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.ranktext2 {
  color: white;
  position: absolute;
  top: 12%;
  right: 53%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.ranktext3 {
  color: white;
  position: absolute;
  top: 13%;
  right: 51%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.ranktextPremium {
  color: black;
  position: absolute;
  top: 31%;
  right: 6%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

/* RankList */
.listBox {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: rgb(255, 255, 255, 0.3);
  border: 1px solid #707070;
  border-radius: 13px;
  width: 53%;
  position: absolute;
  left: 2.4%;
  margin: auto;
  position: relative;
}

.greyboxbg {
  background: transparent url('../public/greyboxbg.png') 0% 0% no-repeat;
  width: 100%;
  position: relative;
  top: 30px;
  left: 27.4%;
}

.greyprofileimg {
  margin-top: 48px;
  margin-left: 50px;
  border-radius: 50px;
  margin-bottom: 110px;
  width: 100px;
  height: 100px;
}

.greyprofileBorder {
  background: transparent url("../public/imageborder.png") 0% 0% no-repeat padding-box;
  position: relative;
  bottom: 50px;
  right: 29px;
}

.greystudentName {
  position: absolute;
  top: 6px;
  left: 12.8%;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.greyranktext {
  color: white;
  position: absolute;
  top: 0%;
  left: -2%;
  text-align: center;
  font-size: 20px;
  font-family: Robo;
  font-weight: bold;
}

.greycourse {
  color: white;
  font-size: 12px;
}

.greyschoolname {
  color: white;
  font-size: 12px;
}

/* RankList */

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #12202d;
  border: 0px solid #707070;
  margin-top: 10rem;
  display: flex;
  height: 180px;
}

@media screen and (max-width: 768px) {
  .header {
    margin-left: 20px;
    display: table;
  }

  .dropdown {
    width: 80%;
    margin-top: 50px;
    justify-content: end;
    position: absolute;
    right: 10%;
    top: 60px;
    justify-content: center;
    text-align: center;
    display: flex;
    ;
  }


  .heading {
    font-size: 18px;
    margin-top: 50px;
  }

  .box {
    background: transparent url("../public/boxBg1.svg") 0% 0% no-repeat;
    /* width: 100%; */
    height: 112px;
  }

  .profileBorder {
    background: transparent url("../public/imageborder1.svg") no-repeat;
    position: relative;
    top: 10px;
    left: -4px;
  }

  .rankBg {
    background: url("../public/rankbg.svg") 0% 0% no-repeat padding-box padding-box transparent;
    position: absolute;
    width: 66px;
    height: 112px;
    margin: 0px;
    left: 88.7%;
    top: 3px;
  }

  .rankBg2 {
    background: url("../public/2ndrankbg.svg") 0% 0% no-repeat padding-box padding-box transparent;
    position: absolute;
    width: 66px;
    height: 112px;
    margin: 0px;
    left: 88.7%;
    top: 23px;
  }

  .rankBg3 {
    background: url("../public/3rdrankbg.svg") 0% 0% no-repeat padding-box padding-box transparent;
    position: absolute;
    width: 66px;
    height: 112px;
    margin: 0px;
    left: 88.7%;
    top: 21px;
  }

  .profileimg {
    margin-top: 24px;
    margin-left: 24px;
    border-radius: 50px;
    margin-bottom: 110px;
    width: 50px;
    height: 50px;
  }

  .ranktext {
    color: white;
    position: absolute;
    top: 27%;
    right: 44%;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
  }

  .ranktext2 {
    color: white;
    position: absolute;
    top: 11%;
    right: 44%;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
  }

  .ranktext3 {
    color: white;
    position: absolute;
    top: 14%;
    right: 41%;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
  }

  .ranktextPremium {
    color: black;
    position: absolute;
    top: 20%;
    right: 2%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

  .studentName {
    position: absolute;
    top: 35px;
    left: 25%;
    color: #003482;
    font-size: 12px;
  }

  .studentName2 {
    position: absolute;
    top: 35px;
    left: 25%;
    color: #003482;
    font-size: 12px;
  }

  .studentName3 {
    position: absolute;
    top: 35px;
    left: 25%;
    color: #003482;
    font-size: 12px;
  }

  .course {
    color: #07683b;
    font-size: 8px;
  }

  .schoolname {
    color: #F08C21;
    font-size: 8px;
  }

  /* RankList */
  .listBox {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: rgb(255, 255, 255, 0.3);
    border: 1px solid #707070;
    border-radius: 13px;
    width: 95%;
    margin: 0px;

  }

  .greyboxbg {
    background: transparent url("../public/greyboxbg1.svg") 0% 0% no-repeat;
    width: 100%;
    position: relative;
    top: 66px;
    right: 0;
  }

  .greyprofileimg {
    margin-top: 25px;
    margin-left: 24px;
    border-radius: 50px;
    margin-bottom: 110px;
    width: 50px;
    height: 50px;
  }

  .greyprofileBorder {
    background: transparent url("../public/imageborder1.svg") 0% 0% no-repeat padding-box;
    position: relative;
    bottom: 25px;
    right: 29px;
  }

  .greystudentName {
    position: absolute;
    top: 0%;
    left: 10%;
    color: white;
    font-size: 12px;
  }

  .greyranktext {
    color: white;
    position: absolute;
    top: -3%;
    right: 60px;
    text-align: center;
    font-size: 12px;
    font-family: Robo;
    font-weight: bold;
  }

  .greycourse {
    color: white;
    font-size: 8px;
  }

  .greyschoolname {
    color: white;
    font-size: 8px;
  }

  /* RankList */

}