.head-top-container {
    /* background-color: #12202d; */
}

.header-container {
    display: flex;
    justify-content: space-around;
    align-items: center;

}
.header-icon-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.header-icon {
    /* margin-right: 20px; */
}

.header-heading {
    display: flex;
    justify-content: center;
    font-size: 30px;
    flex: 1;
    padding: 1rem;
    color: #fff;
}

.search-btn-input {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: white;
    padding: 10px;
    width: 54%;
    border: 1px solid #ff7900;
    border-radius: 28px;
}

.form-control {
    display: block;
    width: 80%;
    padding: 0.375rem 0.75rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: none;
}

.search-btn-input input:focus {
    outline: none;
    border: none;
}

.search-btn {
    outline: none;
    border: none;
    background-color: transparent;
}



.header-search-input {
    display: flex;
    justify-content: center;
    font-size: 30px;
    flex: 1;
    margin-right: 15rem;
}


.tab-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #162744;
    padding: 15px;
}

.each-tab {
    border: none;
    border-radius: 40px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    padding: 25px 18px;
    margin-right: 1rem;
    /* min-width: 200px; */
    font-size: .9rem;
    line-height: .9rem;
    background-color: #f9fafb;
    width: 280px;
}

.each-tab:hover {
    background-color: #ebcccc;
}

.flex-column {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 5px;
}

.flex-column p {
    padding: 0;
    margin: 2px;
}


.top-three-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-left: 5px;
}

.body-container {
    /* background-color: #fff; */
    padding: 10px 5px;
}

.card-box {
    line-height: 21px;
    color: #fff;
    width: 21%;
    padding: 30px 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.course-name-top-three {
    margin-bottom: 1rem;
}

.extra-margin {
    margin-top: 3rem;
}

.alignment-style {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.top-three-image {
    padding: 20px 10px;
    border-radius: 50%;
}

.rank-listing-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background: transparent linear-gradient(180deg, #9f27c7, #6d23c0) 0 0 no-repeat padding-box;
    border-radius: 20px;
    color: #fff;
    margin: 0rem;
    padding: 1rem 3rem;
    margin-top: 1rem;
}

.rank-listing-container-base {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: #919191;
    /* background: transparent linear-gradient(180deg, #c491b9, #9268c2) 0 0 no-repeat padding-box; */
    border-radius: 20px;
    color: #fff;
    margin: 0rem;
    padding: 1rem 3rem;
    margin-top: 1rem;
}

.rank-listing-main {
    /* position: relative; */
    padding: 3rem 5rem;
    display: flex;
    flex-direction: column;
    width: 60%;
    word-wrap: break-word;
    /* background-color: #fff; */
    background-clip: border-box;
    /* border: 1px solid rgba(0, 0, 0, .125); */
    border-radius: 1.25rem;
}

.center-flex-contain {
    display: flex;
    justify-content: center;
}

.ranklist-detail-contain {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.ranklist-rank-no-base{
    margin-left: -27px;
    font-size: 15px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.start-flex-align {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
}

.extra-right {
    margin-right: 9rem;
}

.ranklist-student-name {
    font-size: 22px;
}

.ranklist-course-name {
    margin-left: 1rem;
    font-size: 17px;
}

.ranklist-school-name {
    font-size: 15px;
}

.ranklist-rank-no {
    font-size: 25px;
}

/* .dropdown-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
} */

.dropdown-style {
    width: 20%;
    margin-right: 3rem;
}



.Student-name-top-three {
    margin-bottom: 1rem;
}

.header-icon {
    /* background-color: #7e25c3; */
}

.header-icon-container {
    margin: 2rem;
}



.rank-name {
    display: none;
}

/* search bar start*/
.search-container {
    background-color: #162744;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

/* .search-container .css-13cymwt-control {
    border-radius: 24px;
    width: 261px;
    padding: 6px;
    font-size: 13px;
    margin-right: 1rem;
} */

/* .search-container .css-13cymwt-control{
    margin-right: 10px;
    width: 235px;
} */

.header-tab-search-btn {
    border-radius: 5px;
    padding: 12px;
    font-size: 12px;
    background-color: #089328;
    color: white;
    letter-spacing: 2px;
    border: none;
}



/* .reversing-top-two{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
} */

.rank-contain-2{
    margin-right: 4px;
}
.rank-contain-base{
    margin-right: 4px;
}


.select-design{
    border-radius: 5px;
    padding: 10px;
    width: 20%;
    margin-right: 10px;
}

.select-design option {
    width: 10%;
    padding: 10px;
  }

/* searchbar end */
@media screen and (max-width: 768px) {

    .select-design{
        border-radius: 5px;
        padding: 10px;
        width: 70%;
        margin-right: 0px;
        margin-top: 10px;
        margin-bottom: 7px;
    }

    .extra-right {
        margin-right: 0rem;
    }

    /* search bar start*/
    .search-container {
        flex-direction: column;
    }

    .search-container .css-13cymwt-control {
        margin-right: 0rem;
        margin-bottom: 0.5rem;
    }

    .searching-style .search-container:nth-child(2) {
        padding-top: 0;
    }

    .searching-style .search-container:nth-child(1) {
        padding-bottom: 0;
    }

    

    /* searchbar end */


    

    .header-icon-container {
        margin: 1rem;
    }

    .header-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }

    .start-flex-align {
        flex-direction: column;
        margin-right: 0rem;
    }

    .card-box:nth-child(1) {
        order: 1;
    }

    .card-box:nth-child(2) {
        order: 2;
    }

    .rank-listing-container:nth-child(1){
        order: 1 !important;
    }
    .rank-listing-container:nth-child(2){
        order: 2 !important;
    }
    .rank-listing-container:nth-child(3){
        order: 3;
    }


    .tab-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px;
    }

    .each-tab {
        margin: 5px;
        justify-content: flex-start;
        width: 280px;
    }

    .top-three-image {
        padding: 7px 10px;
    }

    .top-three-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        margin-left: 0px;
    }

    .card-box {
        padding: 15px 0px;
        width: 100%;
        margin-top: 2rem;
    }

    .extra-margin {
        margin-top: 0px;
    }

    .rank-listing-main {
        /* position: relative; */
        padding: 1rem 3rem;
        width: 80%;
    }

    .rank-listing-container {
        padding: 15px 1rem;
        margin-top: 1rem;
        flex-direction: column-reverse;
    }

    .rank-listing-container-base {
        padding: 15px 1rem;
        margin-top: 1rem;
        flex-direction: column-reverse;
    }

    .ranklist-student-name {
        font-size: 19px;
        line-height: 23px;
        text-align: center;
    }

    .ranklist-course-name {
        margin-left: 0rem;
        font-size: 16px;
        line-height: 23px;
        margin-top: 0.5rem;
    }

    .ranklist-detail-contain {
        align-items: center;
        flex-direction: column;
    }

    .ranklist-school-name {
        font-size: 14px;
        line-height: 23px;
        text-align: center;
    }

    .rank-contain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
    }
    .rank-contain-2{
        display: none;
    }
    .rank-contain-base{
        display: none;
    }

    .rank-name {
        margin-right: 0.5rem;
        display: block;
    }

    .ranklist-rank-no {
        font-size: 20px;
    }

    .dropdown-style {
        width: 50%;
        margin-right: 1rem;
    }

    .header-search-input {
        margin-right: 0;
        width: 100%;
    }

    .search-btn-input {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        background-color: white;
        padding: 10px;
        width: 80%;
    }

    .form-control {
        width: 80%;
    }
}